import axios from "../axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const DashboardContext = createContext();

export const DashboardProvider = ({children}) => {
    const [dashboardDetails,setDashboardDetails] = useState(null);
    const [errorPage, setErrorPage] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    async function dashboard() {
        await axios
          .get("dashboard", {})
          .then((res) => {
            setDashboardDetails(res)
          })
          .catch((error) => {
            if (error.response && error.response.status === 403) setLoggedOut(true);
            else setErrorPage(true);
          });
      }

    useEffect(()=>{
      dashboard()
    },[])


    return(
        <DashboardContext.Provider value={{dashboardDetails,errorPage,loggedOut,setDashboardDetails}}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboardContext = () => {
    return useContext(DashboardContext)
}
import React from 'react';

export default function Loader ({className}) {
    return (
        <section className = "loader">
            <div className={`${className || "alignLoader"}`}>
                <div className="spinner-border" role="status">
                </div>
                <span className="text-secondary ml-1">Loading...</span>
            </div>
        </section>
    )
}
import axios from "axios";
import { AUTH_TOKEN, BACKEND_URL } from "./urls";

const customInstance = axios.create({
  baseURL: BACKEND_URL,
});

if (process.env.REACT_APP_ENVIRONMENT === "LOCALHOST")
  customInstance.defaults.headers.common["Authorization"] = AUTH_TOKEN;
else customInstance.defaults.withCredentials = true;

export default customInstance;

import React, { Suspense, lazy } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "iframe-resizer";
import Loader from "./Components/loader";
import { DashboardProvider } from "./Contexts/DashboardContext";

const Discuss = lazy(() => import("./Discuss/discuss"));
const CommentThread = lazy(() => import("./Discuss/commentThread"));
const DashboardRoutes = lazy(() => import("./CommentDashboard/index"));

function App() {
  return (
    <DashboardProvider>
      <div className="App">
        <Router>
          <Suspense fallback={<Loader className="initialAlignLoader" />}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  return <Redirect to="/dashboard" />;
                }}
              />
              <Route
                path="/discuss/:postId/:postTitle/:domainName"
                component={Discuss}
              ></Route>
              <Route
                path="/comment/:commentId/:domain"
                component={CommentThread}
              ></Route>
              <Route path="/dashboard" component={DashboardRoutes}></Route>
            </Switch>
          </Suspense>
        </Router>
      </div>
    </DashboardProvider>
  );
}

export default App;
